import React, {FC, useEffect, useRef, useState} from 'react';
import EmptyMessage from "../../components/empty/EmptyMessage";


const KTs: FC = () => {




    return (
        <div className="nk-content">
            <div className="row">

                <div className="col-md-6 col-xxl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <div className="fs-6 text-light mb-0">On-boarding Process </div>
                                <a href="https://copygen.themenio.com/dashboard/history.html" className="link">See
                                    History</a></div>
                            <h5 className="fs-1">452 <small className="fs-3">words</small></h5>
                            <div className="progress progress-md">
                                <div
                                    className="progress-bar bg-gradient-custom gradient-start-cyan gradient-middle-purple-light gradient-end-pink-light gradient-angle-45"
                                    data-progress="74%"  style={{width: '74%'}}></div>
                            </div>
                            <div className="fs-7 text-light mt-1"><span className="text-dark">1548</span>/2000 free words
                                generated
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <div className="fs-6 text-light mb-0">Legal Process</div>
                                <a href="https://copygen.themenio.com/dashboard/history.html" className="link">See
                                    History</a></div>
                            <h5 className="fs-1">452 <small className="fs-3">words</small></h5>
                            <div className="progress progress-md">
                                <div
                                    className="progress-bar bg-gradient-custom gradient-start-cyan gradient-middle-purple-light gradient-end-pink-light gradient-angle-45"
                                    data-progress="74%"  style={{width: '74%'}}></div>
                            </div>
                            <div className="fs-7 text-light mt-1"><span className="text-dark">1548</span>/2000 free words
                                generated
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xxl-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <div className="fs-6 text-light mb-0">Tech Stacks</div>
                                <a href="https://copygen.themenio.com/dashboard/history.html" className="link">See
                                    History</a></div>
                            <h5 className="fs-1">452 <small className="fs-3">words</small></h5>
                            <div className="progress progress-md">
                                <div
                                    className="progress-bar bg-gradient-custom gradient-start-cyan gradient-middle-purple-light gradient-end-pink-light gradient-angle-45"
                                    data-progress="74%"  style={{width: '74%'}}></div>
                            </div>
                            <div className="fs-7 text-light mt-1"><span className="text-dark">1548</span>/2000 free words
                                generated
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default KTs;
