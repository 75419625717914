import React, { FC, lazy, Suspense } from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';

import  './app.scss'

import Login from './pages/authentication/Login';
import Register from './pages/authentication/Register';

import PrivateRoute from './PrivateRoute';

import PageNotFound from './pages/404/PageNotFound';
import ForgotPassword from "./pages/authentication/ForgotPassword";
import VerifyEmail from "./pages/authentication/VerifyEmail";


import Dashboard from './pages/dashboard/Dashboard';
import WebsitesContainer from './pages/websites/WebsitesContainer';
import WebsiteContainer from "./pages/websites/WebsiteContainer";
import SdkContainer from "./pages/sdk/SdkContainer";
import AccountContainer from "./pages/account/AccountContainer";
import PricingPlanContainer from "./pages/pricingPlans/PricingPlanContainer";
import Logout from "./pages/logout/Logout";
import ShopsContainer from "./pages/shops/ShopsContainer";
import ShopContainer from "./pages/shops/ShopContainer";
import PublicShopDisplay from "./pages/shops/pages/PublicShopDisplay";
import ShopDisplayLayout from "./pages/shops/tabs/ShopDisplayLayout";
import OrganizationContainer from "./pages/organizations/OrganizationContainer";
import OrganizationsContainer from "./pages/organizations/OrganizationsContainer";
import OrganizationFeatureContainer from "./pages/organizations/public-app/OrganizationFeatureContainer";
import Test from "./Test";
import ResetPassword from "./pages/authentication/ResetPassword";
import KTsContainer from "./pages/kt/KTsContainer";
import KTForm from "./pages/kt/KTForm";
import KTs from "./pages/kt/KTs";
import KTContainer from "./pages/kt/KTContainer";

const App: FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/test" element={<Test />} />
                <Route path="/login" element={<Login />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset-password" element={<ResetPassword />} />

                <Route path="/public/shop/:id/display/:displayId/:apiKey" element={<PublicShopDisplay />} />

                <Route
                    path="/dashboard"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/websites"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <WebsitesContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/knowledge-center?"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <KTsContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/knowledge-center/:_page?"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <KTContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/organizations"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <OrganizationsContainer/>
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/shops"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <ShopsContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/plans"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <PricingPlanContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/logout"
                    element={
                        <React.Suspense fallback={<>...</>}>
                                <Logout />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/website/:id/:_page?"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <WebsiteContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/organization/:id/:_page?/:groupId?/:_groupPage?"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <OrganizationContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />    <Route
                    path="/chat-organization/:id"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <OrganizationFeatureContainer />
                        </React.Suspense>
                    }
                />
                <Route
                    path="/shop/:id/:_page?"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <ShopContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/shop/:id/display/:displayId"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <ShopDisplayLayout  />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />

                <Route
                    path="/sdk"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <SdkContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />

                <Route
                    path="/account/:_page?"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PrivateRoute>
                                <AccountContainer />
                            </PrivateRoute>
                        </React.Suspense>
                    }
                />

                <Route
                    path="*"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <PageNotFound />
                        </React.Suspense>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
