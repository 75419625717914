import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "react-rainbow-components";
import KTForm from "./KTForm";
import './kt.scss'

const KTContainer: FC = () => {


    const {  _page } = useParams();
    const navigate = useNavigate();


    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">

                        <div className="nk-block-head nk-page-head">
                            <div className="card p-2 border-0">
                                <div className="nk-block-head-between flex-wrap gap g-2">

                                    <div className="nk-block-head-content">
                                        <h5 className="fs-4 fw-light m-0">Knowledge Center</h5>
                                        <p className={`fs-5`}>List of websites you enabled chatbot.</p>
                                    </div>

                                    <div>

                                        <Button
                                            label="Create New"
                                            variant="neutral"
                                            size={`small`}
                                            className="rainbow-m-around_medium me-2"
                                            borderRadius="semi-square"
                                            onClick={() => {
                                                navigate('/knowledge-center/create')
                                            }}

                                        >
                                            <em className="icon ni ni-save"/>
                                            <span className='ms-2'>Save to Draft</span>
                                        </Button>
                                        <Button
                                            label="Create New"
                                            variant="brand"
                                            size={`small`}
                                            className="rainbow-m-around_medium"
                                            borderRadius="semi-square"
                                            onClick={() => {
                                                navigate('/knowledge-center/create')
                                            }}

                                        >
                                            <span className='ms-2'>Save KT</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="nk-block">

                            <KTForm />

                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};
export default KTContainer;
