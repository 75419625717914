import React, {FC, useEffect, useRef, useState} from 'react';
import WindowToolImg from "../../../assets/window-tool.svg";
import { Accordion, AccordionSection, } from 'react-rainbow-components';
import FileUpload from "../../components/upload/fileUpload";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import FormInput from "../../components/form/FormInput";

interface FileUploadHandle {
    uploadFiles: () => Promise<void>;
}
const KTForm: FC = () => {

    const fileUploadRef = useRef<FileUploadHandle>(null);

    const [fileCount, setFileCount] = useState(0);



    return (
        <div className={`row`}>
            <div className="col-xl-4 col-sm-12  mb-0 mb-sm-2">

                <div className="card shadow-none border-0 mb-0">
                    <div className="card-header bg-white">
                        <h5 className="card-title m-0">Setup Your Knowledge Base</h5>
                    </div>
                    <div className="card-body">

                        <div className="form-group mb-2">
                            <label className="form-label" >Tag</label>
                            <div className="form-control-wrap">


                                <TagsInput value={['a']} onChange={() => {
                                }}/>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" >Ta 1g</label>
                            <div className="form-control-wrap">

                                <FileUpload ref={fileUploadRef} uploadType={`organization`} _id={'dfdf'}
                                            onFileCountChange={(count) => setFileCount(count)}/>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="col-xl-8  col-sm-12">

                <Accordion id="accordion-kt"  >
                    <AccordionSection label="Configure Chat Settings" >



                        <div className={`row mb-2`}>
                            <FormInput
                                label="KT Name"
                                name="name"
                                type="text"
                                placeholder="Enter Name"
                                value={``}
                                onChange={()    => {}}
                                onBlur={()    => {}}
                            />
                        </div>
                        <div className={`row`}>
                            <div className={`col-6`}>
                                <FormInput
                                    label="KT Name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    value={``}
                                    onChange={()    => {}}
                                    onBlur={()    => {}}
                                />
                            </div>
                            <div className={`col-6`}>
                                <FormInput
                                    label="KT Name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    value={``}
                                    onChange={()    => {}}
                                    onBlur={()    => {}}
                                />
                            </div>
                        </div>
                    </AccordionSection>
                    <AccordionSection label="Access Control Settings">
                        A rainbow is a meteorological phenomenon that is caused by reflection, refraction
                        and dispersion of light in water droplets resulting in a spectrum of light appearing
                        in the sky.
                    </AccordionSection>
                    <AccordionSection label="Preview">
                        A rainbow is a meteorological phenomenon that is caused by reflection, refraction
                        and dispersion of light in water droplets resulting in a spectrum of light appearing
                        in the sky.
                    </AccordionSection>
                </Accordion>
            </div>

        </div>
    );
};
export default KTForm;
