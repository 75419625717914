import React, {FC, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Badge, Button, ButtonMenu, MenuItem} from "react-rainbow-components";
import WebsiteDashboard from "../websites/tabs/WebsiteDashboard";
import WebsiteDocuments from "../websites/tabs/WebsiteDocuments";
import WebsiteChatHistory from "../websites/tabs/WebsiteChatHistory";
import WebsiteSettings from "../websites/tabs/WebsiteSettings";
import WebsiteChatBotSettings from "../websites/tabs/WebsiteChatBotSettings";
import {BrainaqueChatBot} from "brainaque-chat-sdk";
import {toastMessage} from "../../../utils/util";
import Loader from "../../components/loader/Loader";
import dayjs from "dayjs";
import EmptyMessage from "../../components/empty/EmptyMessage";
import WebsiteForm from "../websites/modals/WebsiteForm";
import WebsiteManage from "../websites/modals/WebsiteManage";
import KTs from "./KTs";


const KTsContainer: FC = () => {

    const [activeTab, serActiveTab] = useState('active');

    const navigate = useNavigate();

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">

                        <div className="nk-block-head nk-page-head">
                            <div className="card p-2 border-0">
                                <div className="nk-block-head-between flex-wrap gap g-2">

                                    <div className="nk-block-head-content">
                                        <h5 className="fs-4 fw-light m-0">Knowledge Center</h5>
                                        <p className={`fs-5`}>List of websites you enabled chatbot.</p>
                                    </div>

                                    <Button
                                        label="Create New"
                                        variant="brand"
                                        size={`small`}
                                        className="rainbow-m-around_medium"
                                        borderRadius="semi-square"
                                        onClick={() => {
                                            navigate('/knowledge-center/create')
                                        }}

                                    >
                                        <em className="icon ni ni-plus"/>
                                        <span className='ms-2'>Create New</span>
                                    </Button>
                                </div>
                            </div>

                        </div>
                        <div className="nk-block">
                            <div className="card  border-0">
                                <ul className="nav nav-tabs nav-tabs-s1 px-4" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab == 'active' && 'active'}`}
                                                type="button" data-bs-toggle="tab"
                                                onClick={() => serActiveTab('active')} aria-selected="true"
                                                role="tab">Active
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${activeTab == 'deactivated' && 'active'}`}
                                                type="button" data-bs-toggle="tab"
                                                onClick={() => serActiveTab('deactivated')} aria-selected="false"
                                                role="tab">Draft
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content">

                                    <KTs/>

                                    <div className="table-responsive tab-pane fade active show"
                                         id="recents-documents-tab-pane"
                                         role="tabpanel">

                                        <EmptyMessage
                                            type="website"
                                            title="No websites added."
                                            description="You have not added any websites yet. Start adding to manage your favorite sites."
                                            buttonText="Add Website"
                                            buttonCallback={() => {

                                            }}
                                        />


                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default KTsContainer;
